import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function PrincipalMessage() {
  return (
    <Layout>
      <SEO
        title="Principal's Message | Chinmaya Vidyalaya"
        description="A message encapsulating the school focus and philosophy, from Ms. Archana Soni, Principal, Chinmaya Vidyalaya Vasant Vihar."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="principalmessage">
        <h1 className="heading">At The Helm</h1>
        <div className="principalmessage__heading2">Principal's Message</div>

        <div className="row principalmessage__row">
          <div className="col col-12 col-lg-3">
            <img
              className="principalmessage__img"
              src="https://www.chinmayavvdelhi.ac.in/images/Blackele/Archana_Soni.jpg"
            ></img>
            <div className="principalmessage__name">Ms. Archana Soni</div>
          </div>
          <div className="col col-12 col-lg-9">
            <p className="principalmessage__text">
              It is to everyone’s knowledge that a disciple and discipline are
              closely connected; the former without the latter is meaningless.
              School life is the preparatory ground that prepares you for life
              in terms of emulating the desirable traits for example
              punctuality, honesty, respect for elders etc. Self discipline and
              restraint practiced during your student days will give you that
              cutting edge in shaping you for the future. True Education serves
              as the backbone of your personality. Due importance given to the
              basics of academics now will help you find further direction in
              life and blossom as individuals with a special flavor. In this
              venture we are as responsible towards our students as the parents
              towards their wards. Our joint effort will bear fruit. Let us
              together put our best foot forward!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
